<!--  -->
<template>
  <div>
    <!-- header 导航栏 -->
    <div class="nav">
      <div
        style="
          width: 233px;
          height: 80px;
          background: #0762cb;
          display: flex;
          flex-direction: column;
          justify-content: center;
        "
      >
        <div
          style="
            margin-left: 10px;
            font-weight: 400;
            color: #fff;
            font-size: 20px;
          "
        >
          BOAI MEDICINE
        </div>
        <div
          style="
            margin-left: 10px;
            font-weight: 400;
            color: #fff;
            font-size: 20px;
          "
        >
          博爱医药
        </div>
      </div>
      <ul class="center">
        <li>
          <a :class="{ active: itemIndex == 1 }" 
            @click="btnClick(1)">首页</a>
        </li>
        <li  @click="btnClick(2)">
          <a :class="{ active: itemIndex == 2 }">关于我们</a>
        </li>
        <li @click="btnClick(3)">
          <a :class="{ active: itemIndex == 3 }">会员服务</a>
        </li>
        <li @click="btnClick(4)">
          <a :class="{ active: itemIndex == 4 }">博爱大事记</a>|
        </li>
        <li @click="btnClick(5)">
          <a :class="{ active: itemIndex == 5 }">供应商平台</a>|
        </li>
        <li @click="btnClick(6)">
          <a :class="{ active: itemIndex == 6 }">人才招聘</a>|
        </li>

        <li>
          <a href="#"
            ><img
              src="../assets/web/sousuo.png"
              alt=""
              style="width: 12px; height: 12px; margin-left: 10px"
          /></a>
        </li>
        <li>
          <a href="#"
            ><img
              src="../assets/web/phone.png"
              alt=""
              style="width: 12px; height: 12px"
            />&nbsp;&nbsp;&nbsp; 0417-5675777</a
          >
        </li>
      </ul>
    </div>
    <!-- 1是首页  2是关于我们 3是会员服务 4是博爱大事记 5是供应商平台 6是人才招聘-->
    <div v-if="this.itemIndex == 1">
      <!-- banner -->
      <div class="top-rowBanner"></div>
      <!-- 关于博爱 -->
      <!-- <div style="display: flex; justify-content: center; align-items: center">
        <div style="width: 1000px">
          <div style="font-size: 35px; color: #0762cb; margin-top: 70px">
            关于博爱
          </div>
          <div
            style="
              width: 80px;
              height: 2.3px;
              background: #0762cb;
              margin-top: 12px;
            "
          ></div>
          右边图片 左边文字
          <div style="display: flex">
            <div
              class="word"
              style="width: 52%; margin-top: 30px; line-height: 26px"
            >
              <span style="margin-left: 25px">营口</span
              >博爱医药连锁有限公司成立于2001年，是一家全国性的大型医药连锁企业，注册资本1000万人民币，是集药品销售、产品开发代理、连锁与特许加盟药店等多种业务为一体的连锁公司。
              <br />
              <span style="margin-left: 25px">公司</span
              >公司秉承着“聚天地之广博，汇人间之大爱”的理念，采用国内先进的网络远程连锁管理模式以及连锁零售与物流配送相结合的营销模式，发展至今已拥有上百家连锁店，且顺利通过了国家药品管理GSP认证。
              <br />
              <span style="margin-left: 25px">公司</span
              >公司主营OTC药品、中成药、中药饮片、中药材、化学制剂药品、原料药、生物药品、保健食品、药妆产品、计划生育用品、医疗器械等万种商品。
              <br />
              <span style="margin-left: 25px">产品</span
              >产品从供货渠道、物流、仓储、销售等各个环节均符合严格的质量控制体系。公司为旗下连锁药店及加盟药店提供药房全方位的经营支持与辅导，统一标准化经营，帮助药店降低运营成本，
              发挥连锁优势，提升营业额和利润。同时，公司不断增加执业药师的配备数量，提升销售人员的专业水平，提高药品质量管理和药学服务水平，以满足消费者用药指导和咨询的需求。
            </div>
            <div class="picture" style="margin-left: 100px; margin-top: 20px">
              <img
                src="../assets/web/bannerys.jpg"
                style="
                  width: 380px;
                  height: 520px;
                  position: absolute;
                  z-index: 999;
                "
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div></div>
        <div
          class="blue"
          style="
            height: 120px;
            background: #0762cb;
            width: 76.3%;
            position: relative;
          "
        >
          <div
            style="
              font-size: 22px;
              font-weight: 500;
              color: #fff;
              margin-left: 50px;
              position: absolute;
              top: 30px;
            "
          >
            新领域 新模式 新思维
          </div>
          <div
            style="
              font-size: 18px;
              font-weight: 500;
              color: #fff;
              margin-left: 50px;
              position: absolute;
              top: 70px;
            "
          >
            New fields, new models, and new thinking
          </div>
        </div>
      </div> -->
      <!-- 后修改的地图加简介 -->
      <div style="display:flex;justify-content:center">
        <div style="width:1000px;height:560px;display:flex;position: relative;">
        <!-- map -->
        <img src="../assets/web/map1.png" alt="" style="width:65%;height:560px;">
        <!-- intrduce -->
        <img src="../assets/web/jx.png" alt="" style="width:27%;height:390px;margin-top:50px;">
        <div style="position:absolute;top:67px;z-index:999;left:670px;font-size:20px;font-weight:700;color:#fff">品 牌 简 介</div>
        <div style="position:absolute;top:96px;z-index:999;left:670px;font-size:17px;color:#fff">家门口的平价药房</div>
        <div
              class="word"
              style="position:absolute;top:126px;z-index:999;left:670px;font-size:10px;color:#fff;width:232px;line-height:14px"
            >
              <span style="margin-left: 25px">营口</span
              >博爱医药连锁有限公司成立于2001年，是一家全国性的大型医药连锁企业，注册资本1000万人民币，是集药品销售、产品开发代理、连锁与特许加盟药店等多种业务为一体的连锁公司。
              <br />
              <span style="margin-left: 25px">公司</span
              >公司秉承着“聚天地之广博，汇人间之大爱”的理念，采用国内先进的网络远程连锁管理模式以及连锁零售与物流配送相结合的营销模式，发展至今已拥有上百家连锁店，且顺利通过了国家药品管理GSP认证。
              <br />
              <span style="margin-left: 25px">公司</span
              >公司主营OTC药品、中成药、中药饮片、中药材、化学制剂药品、原料药、生物药品、保健食品、药妆产品、计划生育用品、医疗器械等万种商品。
              <br />
              <span style="margin-left: 25px">产品</span
              >产品从供货渠道、物流、仓储、销售等各个环节均符合严格的质量控制体系。公司为旗下连锁药店及加盟药店提供药房全方位的经营支持与辅导，统一标准化经营，帮助药店降低运营成本，
              发挥连锁优势，提升营业额和利润。同时，公司不断增加执业药师的配备数量，提升销售人员的专业水平，提高药品质量管理和药学服务水平，以满足消费者用药指导和咨询的需求。
            </div>
        <!-- <div style="position:absolute;top:100px;z-index:999;left:700px">品牌简介</div> -->
        </div>
      </div>
      <!-- 长举行 -->
      <div class="top-rowBanner666" style="display:flex;flex-direction:column;align-items:center">
      <div style="font-size:28px;font-weight:600;color:#fff;margin-top:26px">品 牌 大 事 记</div>
      <div style="color:#D5DEF3;margin-top:3px">博爱医药的历史时刻</div>
      <div style="margin-top:1px">
        <img src="../assets/web/time.png" alt=""  style="width:1100px">
      </div>
      </div>
      <!-- 四大优势 -->
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 1000px;
          "
        >
          <div
            style="
              width: 1000px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #0762cb;
              font-size: 32px;
              font-weight: 600;
              margin-top: 50px;
            "
          >
            四 大 优 势
          </div>
          <div
            style="
              width: 100px;
              height:2px;
              background: #0762cb;
              margin-top: 20px;
            "
          ></div>
          <div
            style="
              width: 1000px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #adadad;
              font-size: 22px;
              margin-top: 10px;
            "
          >
            够健康 够快乐
          </div>
        </div>
        <div
          style="
            width: 1000px;
            height: 259px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <div
            style="
              width: 100%;
              height: 259px;
            
              display: flex;
              justify-content: space-between;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              "
            >
              <div
                style="
                  width: 90px;
                  height: 90px;
                  background: #0762cb;
                  border-radius: 45px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img
                  src="../assets/web/ok.png"
                  alt=""
                  style="width: 60px; height: 60px"
                />
              </div>
              <div style="margin-top: 20px">国家认证 资质齐全</div>
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              "
            >
              <div
                style="
                  width: 90px;
                  height: 90px;
                  background: #0762cb;
                  border-radius: 45px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img
                  src="../assets/web/zheng.png"
                  alt=""
                  style="width: 60x; height: 60px"
                />
              </div>
              <div style="margin-top: 20px">药企直采 正品保证</div>
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              "
            >
              <div
                style="
                  width: 90px;
                  height: 90px;
                  background: #0762cb;
                  border-radius: 45px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img
                  src="../assets/web/yisheng.png"
                  alt=""
                  style="width: 60px; height: 60px"
                />
              </div>
              <div style="margin-top: 20px">职业药师 在线咨询</div>
            </div>

            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              "
            >
              <div
                style="
                  width: 90px;
                  height: 90px;
                  background: #0762cb;
                  border-radius: 45px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img
                  src="../assets/web/ps.png"
                  alt=""
                  style="width: 60px; height: 60px"
                />
              </div>
              <div style="margin-top: 20px">隐私配送 无忧用药</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 六大平台 尽情选择 -->
      <div style="display:flex;align-items:center;justify-content:center">
         <div style="width:1000px;height:270px;background:#0762CB;border-radius:10px;display:flex;align-items:center;flex-direction:column">
         <div style="color:#fff;font-size:28px;font-weight:600;margin-top:30px">六 大 平 台 . 尽 情 选 择</div>
         <div style="display:flex;justify-content:space-around;height:180px;width:900px;align-items:center">
          <div style="display:flex;flex-direction:column;justify-content:center;align-items:center">
            <img src="../assets/web/tm.png" alt="" style="width:75px;height:75px">
            <div style="margin-top:15px;color:#fff;font-weight:600">天 猫</div>
          </div>
          <div style="display:flex;flex-direction:column;justify-content:center;align-items:center">
            <img src="../assets/web/jd.png" alt="" style="width:75px;height:75px">
            <div style="margin-top:15px;color:#fff;font-weight:600">京 东</div>
          </div>
          <div style="display:flex;flex-direction:column;justify-content:center;align-items:center">
            <img src="../assets/web/pdd.png" alt="" style="width:75px;height:75px">
            <div style="margin-top:15px;color:#fff;font-weight:600">拼 多 多</div>
          </div>
          <div style="display:flex;flex-direction:column;justify-content:center;align-items:center">
            <img src="../assets/web/mt.png" alt="" style="width:75px;height:75px">
            <div style="margin-top:15px;color:#fff;font-weight:600">美 团</div>
          </div>
          <div style="display:flex;flex-direction:column;justify-content:center;align-items:center">
            <img src="../assets/web/elm.png" alt="" style="width:75px;height:75px">
            <div style="margin-top:15px;color:#fff;font-weight:600">饿 了 么</div>
          </div>
          <div style="display:flex;flex-direction:column;justify-content:center;align-items:center">
            <img src="../assets/web/boai.png" alt="" style="width:75px;height:75px">
            <div style="margin-top:15px;color:#fff;font-weight:600">博 爱 医 药 馆</div>
          </div>
         </div>
        
        </div>
      </div>
      <!-- 博爱医药服务项目 -->
      <div style="display:flex;flex-direction:column;align-items:center;justify-content:center">
   <div style="width:1000px;height:300px;display:flex;flex-direction:column;justify-content:center">
    <div style="color:#0762CB;font-size:30px;font-weight:600;display:flex;align-items:center;justify-content:center;margin-top:50px">博 爱 医 药 服 务 项 目</div>
     <div style="display:flex;justify-content:space-between;margin-top:20px">
      <img src="../assets/web/tc1.png" alt="" style="width:300px;height:175px">
      <img src="../assets/web/tc2.png" alt="" style="width:300px;height:175px">
      <img src="../assets/web/tc3.png" alt="" style="width:300px;height:175px">
     </div>
  </div>
      </div>
      <!-- 现代化仓库管理-->
      <!-- <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 1000px;
          "
        >
          <div
            style="
              width: 1000px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #0762cb;
              font-size: 32px;
              font-weight: 600;
              margin-top: 50px;
            "
          >
          现代化仓库管理
          </div>
          <div
            style="
              width: 100px;
              height:2px;
              background: #0762cb;
              margin-top: 20px;
            "
          ></div>
          <div
            style="
              width: 1000px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #adadad;
              font-size: 22px;
              margin-top: 10px;
            "
          >
          全程符合国家药监局新版GPS管理
          </div>
        </div>
        <div
          style="
            width: 1000px;
            height: 259px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top:30px
          "
        >
          <div
            style="
              width: 100%;
              height: 259px;
            
              display: flex;
              justify-content: space-between;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              "
            >
              <div
                style="
                 
                 
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img
                  src="../assets/web/kj.jpg"
                  alt=""
                  style="width: 180px; height:220px"
                />
              </div>
           
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              "
            >
              <div
                style="
                  width: 90px;
                  height: 90px;
                  background: #0762cb;
                  border-radius: 45px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img
                  src="../assets/web/sm.jpg"
                  alt=""
                  style="width: 180px; height:220px"
                />
              </div>
    
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              "
            >
              <div
                style="
                  width: 90px;
                  height: 90px;
                  background: #0762cb;
                  border-radius: 45px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img
                  src="../assets/web/ys.jpg"
                  alt=""
                  style="width: 180px; height:220px"
                />
              </div>

            </div>

            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              "
            >
              <div
                style="
                  width: 90px;
                  height: 90px;
                  background: #0762cb;
                  border-radius: 45px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img
                  src="../assets/web/qx.jpg"
                  alt=""
                  style="width: 180px; height:220px"
                />
              </div>
           
            </div>
          </div>
        </div>
      </div> -->
      <!-- 药品种类 -->
      <!-- <div class="top-rowBanner1"></div> -->
      <!-- footer -->
      <div style="width:100%;height:240px;background:#2C445C;margin-top:70px">
       <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center;border-bottom:1px solid #96A2AE ">
        <div style="display:flex;width:500px;height:90px;justify-content:space-between;align-items:center;color:#96A2AE">
        <div>首页</div>
        <div>关于我们</div>
        <div>会员服务</div>
        <div>博爱大事记</div>
        <div>供应商平台</div>
        <div>人才招聘</div>
       </div>
       <div style="width:50px;height:23px;background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;">
        <img src="../assets/web/chaxun.png" alt="" style="width:11px;height:10px;">
        <div style="font-size:12px">搜索</div>
       </div>
       </div>
       <!-- 联系电话  地址  工作时间 -->
       <div>
        <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center; ">
        <div style="width:500px;height:90px;color:#96A2AE;margin-top:30px">
        <div>联系电话：0417-5675777    <span style="margin-left:60px">备案号：辽ICP备19020664号-1</span></div>
        <div style="margin-top:10px">地址：中国·辽宁·大石桥博爱大厦总部</div>
        <div style="margin-top:10px">工作时间：周一至周六08:00-17:00</div>
      
       </div>
       <div style="background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;margin-top:40px">
        <img src="../assets/web/erweima.png" alt="" style="width:80px;height:80px;">
        
       </div>
       </div>
       </div>
      </div>
    </div>
    <div v-if="this.itemIndex == 2">
        <!-- banner -->
        <div class="top-rowBanner2"></div>
        <!-- 关于我们导航栏 公司概况 品质服务 企业荣誉 企业文化 -->
        <div class="top-rowBanner2222" style="display:flex;justify-content:center;align-items:center">
         <div style="width:1000px;height:80px;display:flex;align-items:center;justify-content:space-between">
          <div class="left" style="display:flex">
          <div style="width:78px;height:90px;background:#0762CB;margin-top:-9px;display:flex;align-items:center;justify-content:space-around;flex-direction:column">
            <div></div>
            <img src="../assets/web/a.png" alt="" style="width:54px;height:18px">
            <div style="color:#6A93D9;font-size:16px;font-weight:600">关于我们</div>
            <div></div>
          </div>
          <div style="display:flex;margin-top:30px;justify-content:center;margin-left:30px">
            <img src="../assets/web/sjx.png" alt="" style="width:8px;height:16px;margin-top:2px"> 
         
           <div v-if="this.itemIndexAbout == 1"  style="margin-left:7px">公司概况</div>
           <div v-if="this.itemIndexAbout == 2"  style="margin-left:7px">品质服务</div>
           <div v-if="this.itemIndexAbout == 3"  style="margin-left:7px">企业荣誉</div>
           <div v-if="this.itemIndexAbout == 4"  style="margin-left:7px">企业文化</div>
          </div>

          </div>
          <div class="right" style="display:flex;width:400px;justify-content:space-between">
            <div :class="{ activeAbout: itemIndexAbout == 1  }"  @click="btnClickAbout(1)">公司概况</div>
            <div :class="{ activeAbout: itemIndexAbout == 2  }"  @click="btnClickAbout(2)">品质服务</div>
            <div  :class="{ activeAbout: itemIndexAbout == 3 }"  @click="btnClickAbout(3)">企业荣誉</div>
            <div  :class="{ activeAbout: itemIndexAbout == 4 }"  @click="btnClickAbout(4)">企业文化</div>
          </div>
         </div>
        </div>
         <!-- 公司简介 -->
        <div v-if="this.itemIndexAbout == 1" style="display:flex;justify-content:center;align-items:center;flex-direction:column">
         
        <div style="width:1000px;padding:0 0 50px 0">
         <div style="font-size:30px;padding:50px 0">营口博爱医药连锁有限公司</div>
         <div style="width:180px;height:2px;background:#8B8B97;"></div>
         <div style="padding:50px 0">
          <div
              class="word"
              style="font-size:18px;color:#000;line-height:26px"
            >
              <span style="margin-left: 27px">营口</span
              >博爱医药连锁有限公司成立于2001年，是一家全国性的大型医药连锁企业，注册资本1000万人民币，是集药品销售、产品开发代理、连锁与特许加盟药店等多种业务为一体的连锁公司。
              <br />
              <br />
              <span style="margin-left: 27px">公司</span
              >公司秉承着“聚天地之广博，汇人间之大爱”的理念，采用国内先进的网络远程连锁管理模式以及连锁零售与物流配送相结合的营销模式，发展至今已拥有上百家连锁店，且顺利通过了国家药品管理GSP认证。
              <br />
              <br />
              <span style="margin-left: 27px">公司</span
              >公司主营OTC药品、中成药、中药饮片、中药材、化学制剂药品、原料药、生物药品、保健食品、药妆产品、计划生育用品、医疗器械等万种商品。
              <br />
              <br />
              <span style="margin-left: 27px">产品</span
              >产品从供货渠道、物流、仓储、销售等各个环节均符合严格的质量控制体系。公司为旗下连锁药店及加盟药店提供药房全方位的经营支持与辅导，统一标准化经营，帮助药店降低运营成本，
              发挥连锁优势，提升营业额和利润。同时，公司不断增加执业药师的配备数量，提升销售人员的专业水平，提高药品质量管理和药学服务水平，以满足消费者用药指导和咨询的需求。
            </div>
         </div>
         <div class="top-rowBannerAbout" ></div>
        </div>
         <!-- 品种 -->
        
        </div>
        <!-- 品质服务 -->
        <div v-if="this.itemIndexAbout == 2" style="display:flex;justify-content:center;align-items:center;flex-direction:column">
          <div style="width:1000px;">
           <div style="color:#0762CB;font-size:20px;padding:40px 0 20px 0;">药师团队</div>
           <div style="display:flex;border-bottom:2px dotted grey">
            <div class="left" style="width:65%;height:450px;">
            <div style="font-size:20px">提供药学服务 指导合理用药</div>
            <div style="padding:15px 0">
            <img src="../assets/web/word1.png" alt="" style="width:98%;height:150px">
            </div>
            <div style="margin-top:15px;font-size:20px">药店精英的PK</div>
            <div style="padding:15px 0">
            <img src="../assets/web/word2.png" alt="" style="width:98%;height:150px">
            </div>
            </div>
            <div class="right" style="width:35%;height:400px;">
            <img src="../assets/web/pic1.png" alt="" style="width:92%;margin-left:8%">
            </div>
           </div>
           <div style="color:#0762CB;font-size:20px;padding:40px 0 20px 0;">采购链</div>
           <div style="display:flex;border-bottom:2px dotted grey;padding:0 0 50px 0">
            <div class="left" style="width:65%;">
            <div style="font-size:20px">统一采购,优质优价:</div>
            <div style="padding:15px 0;">
            <img src="../assets/web/word3.png" alt="" style="width:98%;height:50px">
            </div>
            <div style="margin-top:30px;font-size:20px">全城比价，差价返还！</div>
            <div style="padding:15px 0">
            <img src="../assets/web/word4.png" alt="" style="width:98%;height:50px">
            </div>
            </div>
            <div class="right" style="width:35%;">
              <img src="../assets/web/pic2.png" alt="" style="width:92%;margin-left:8%">
            </div>
           </div>
           <div style="color:#0762CB;font-size:20px;padding:40px 0 20px 0;">质控</div>
           <div style="display:flex;border-bottom:2px dotted grey;padding:0 0 40px 0">
            <div class="left" style="width:65%;">
           
            <div style="padding:15px 0">
            <img src="../assets/web/word5.png" alt="" style="width:98%;height:350px">
            </div>
            </div>
            <div class="right" style="width:35%;">
              <img src="../assets/web/pic3.png" alt="" style="width:92%;margin-left:8%"></div>
           </div>
           <div style="color:#0762CB;font-size:20px;padding:40px 0 20px 0;">物流仓储</div>
           <div style="display:flex;flex-direction:column;border-bottom:2px dotted grey;padding:0 0 50px 0">
            <div class="left" style="width:98%;">
           
            <div style="padding:15px 0">
            <img src="../assets/web/word6.png" alt="" style="width:98%;height:50px">
            </div>
            </div>
            <div class="right" style="width:100%;display:flex;justify-content:space-between">
              <img src="../assets/web/wl1.png" alt="" style="width:24%;height:180px">
              <img src="../assets/web/wl2.png" alt="" style="width:24%;height:180px">
              <img src="../assets/web/wl3.png" alt="" style="width:24%;height:180px">
              <img src="../assets/web/wl4.png" alt="" style="width:24%;height:180px">
            
            </div>
           </div>
           <div style="color:#0762CB;font-size:20px;padding:40px 0 20px 0;">合作品牌</div>
           <div style="display:flex;justify-content:center;align-items:center;padding:0 0 70px 0">
           <div style="width:1000px;height:300px;" class="togethter">
            
           </div>
           </div>
      </div>
        </div>
        <!-- 企业荣誉 -->
        <div v-if="this.itemIndexAbout == 3" style="display:flex;justify-content:center;align-items:center;flex-direction:column">
         
        <div style="width:1000px;">
           <div style="color:#0762CB;font-size:20px;padding:40px 0 20px 0;">企 业 荣 誉</div>
           <div style="display:flex;justify-content:space-between;align-items:center;height:230px;padding:20px 0;border-bottom:2px dotted  grey">
            <img src="../assets/web/ry1.png" alt="" style="width:320px;height:210px">
            <img src="../assets/web/ry2.png" alt="" style="width:320px;height:210px">
            <img src="../assets/web/ry3.png" alt="" style="width:320px;height:210px">
          </div>
          <div style="display:flex;justify-content:space-between;align-items:center;height:230px;padding:20px 0;border-bottom:2px dotted  grey">
            <img src="../assets/web/ry4.png" alt="" style="width:320px;height:210px">
            <div style="width:320px;height:210px;display:flex;justify-content:space-between">
              <img src="../assets/web/ry5.png" alt="" style="width:155px;height:210px">
              <img src="../assets/web/ry6.png" alt="" style="width:155px;height:210px">
            </div>
           
            <img src="../assets/web/ry7.png" alt="" style="width:320px;height:210px">
          </div>
          <div style="display:flex;justify-content:space-between;align-items:center;height:230px;padding:20px 0 40px 0;">
            <img src="../assets/web/ry8.png" alt="" style="width:320px;height:210px">
            <img src="../assets/web/ry9.png" alt="" style="width:320px;height:210px">
            <img src="../assets/web/ry10.png" alt="" style="width:320px;height:210px">
          </div>
      </div>
      
        </div>
        <!-- 企业文化 -->
        <div v-if="this.itemIndexAbout == 4" style="display:flex;justify-content:center;align-items:center;flex-direction:column">
          <div style="width:1000px;">
           <div style="color:#0762CB;font-size:20px;padding:40px 0 20px 0;">企 业 文 化</div>
          <div style="display:flex;justify-content:center;align-items:center;padding:20px 0 20px 0;">
            <img src="../assets/web/culture.png" alt="" style="width:500px;height:500px">
          </div>
          <div style="display:flex;justify-content:center;align-items:center;padding:20px 0 0 0;">
            <img src="../assets/web/qywh.png" alt="" style="width:800px;height:330px">
          </div>
      </div>
        </div>
     <!-- 做医药行业的品质保障-->
     <!-- <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          
        "
      >
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 1000px;
            
          "
        >
          <div
            style="
              width: 1000px;
              display: flex;
              justify-content:space-around;
              align-items: center;
              color:#ADADAD;
              font-size: 26px;
              padding:30px 0;
             
            "
          >
          <div>做</div>
          <div>医</div>
          <div>药</div>
          <div>行</div>
          <div>业</div>
          <div>的</div>
          <div>品</div>
          <div>质</div>
          <div>保</div>
          <div>证</div>
                  
          </div>
        
     
        </div>
       
      </div> -->
      <!--成立于2001-->
    <!-- <div style="display:flex;align-items:center;justify-content:center">
       <div style="width:1000px;height:300px;background:#E6EFFA;display:flex;align-items:center;justify-content:center">
        <div
              class="word"
              style="width:90%;  line-height:30px"
            >
              <span style="margin-left: 25px">营口</span
              >博爱医药连锁有限公司成立于2001年，是一家全国性的大型医药连锁企业，注册资本1000万人民币，是集药品销售、产品开发代理、连锁与特许加盟药店等多种业务为一体的连锁公司。
              <br />
              <span style="margin-left: 25px">公司</span
              >公司秉承着“聚天地之广博，汇人间之大爱”的理念，采用国内先进的网络远程连锁管理模式以及连锁零售与物流配送相结合的营销模式，发展至今已拥有上百家连锁店，且顺利通过了国家药品管理GSP认证。
              <br />
              <span style="margin-left: 25px">公司</span
              >公司主营OTC药品、中成药、中药饮片、中药材、化学制剂药品、原料药、生物药品、保健食品、药妆产品、计划生育用品、医疗器械等万种商品。
              <br />
              <span style="margin-left: 25px">产品</span
              >产品从供货渠道、物流、仓储、销售等各个环节均符合严格的质量控制体系。公司为旗下连锁药店及加盟药店提供药房全方位的经营支持与辅导，统一标准化经营，帮助药店降低运营成本，
              发挥连锁优势，提升营业额和利润。同时，公司不断增加执业药师的配备数量，提升销售人员的专业水平，提高药品质量管理和药学服务水平，以满足消费者用药指导和咨询的需求。
            </div>
    </div>
    </div> -->
    <!-- 111map -->
    <!-- <div style="display:flex;align-items:center;justify-content:center">
        <div class="top-rowBanner3" style="width:1000px;">

</div>
    </div> -->
    <!-- footer -->
    <!-- footer -->
    <div style="width:100%;height:240px;background:#2C445C;">
       <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center;border-bottom:1px solid #96A2AE ">
        <div style="display:flex;width:500px;height:90px;justify-content:space-between;align-items:center;color:#96A2AE">
        <div>首页</div>
        <div>关于我们</div>
        <div>会员服务</div>
        <div>博爱大事记</div>
        <div>供应商平台</div>
        <div>人才招聘</div>
       </div>
       <div style="width:50px;height:23px;background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;">
        <img src="../assets/web/chaxun.png" alt="" style="width:11px;height:10px;">
        <div style="font-size:12px">搜索</div>
       </div>
       </div>
       <!-- 联系电话  地址  工作时间 -->
       <div>
        <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center; ">
        <div style="width:500px;height:90px;color:#96A2AE;margin-top:30px">
          <div>联系电话：0417-5675777    <span style="margin-left:60px">备案号：辽ICP备19020664号-1</span></div>
        <div style="margin-top:10px">地址：中国·辽宁·大石桥博爱大厦总部</div>
        <div style="margin-top:10px">工作时间：周一至周六08:00-17:00</div>
      
       </div>
       <div style="background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;margin-top:40px">
        <img src="../assets/web/erweima.png" alt="" style="width:80px;height:80px;">
        
       </div>
       </div>
       </div>
      </div>
    
    </div>
    <div v-if="this.itemIndex == 3">
        <!-- banner -->
        <div class="top-rowBanner5"></div>
            <!-- 关于我们导航栏 公司概况 品质服务 企业荣誉 企业文化 -->
            <div class="top-rowBanner2222" style="display:flex;justify-content:center;align-items:center">
         <div style="width:1000px;height:80px;display:flex;align-items:center;justify-content:space-between">
          <div class="left" style="display:flex">
          <div style="width:78px;height:90px;background:#0762CB;margin-top:-9px;display:flex;align-items:center;justify-content:space-around;flex-direction:column">
            <div></div>
            <img src="../assets/web/b.png" alt="" style="width:54px;height:18px">
            <div style="color:#6A93D9;font-size:16px;font-weight:600">会员服务</div>
            <div></div>
          </div>
          <div style="display:flex;margin-top:30px;justify-content:center;margin-left:30px">
            <img src="../assets/web/sjx.png" alt="" style="width:8px;height:16px;margin-top:2px"> 
           
           <div v-if="this.itemIndexVip == 1"  style="margin-left:7px">会员权益  </div>
           <div v-if="this.itemIndexVip == 2"  style="margin-left:7px">慢病中心  </div>
           <div v-if="this.itemIndexVip == 3"  style="margin-left:7px">老中医咨询</div>
           <!-- <div v-if="this.itemIndexVip == 4"  style="margin-left:7px">企业文化</div> -->
          </div>

          </div>
          <div class="right" style="display:flex;width:400px;justify-content:space-between">
            <div></div>
            <div :class="{ activeAbout:  itemIndexVip == 1  }"  @click="btnClickVip(1)">会员权益  </div>
            <div :class="{ activeAbout:  itemIndexVip == 2  }"  @click="btnClickVip(2)">慢病中心  </div>
            <div  :class="{ activeAbout: itemIndexVip == 3 }"   @click="btnClickVip(3)">老中医咨询</div>
            <!-- <div  :class="{ activeAbout: itemIndexVip == 4 }"   @click="btnClickVip(4)">企业文化</div> -->
          </div>
         </div>
        </div>
         <!-- 会员权益 -->
        <div v-if="this.itemIndexVip == 1" style="display:flex;justify-content:center;align-items:center;flex-direction:column">
         
        <div style="width:1000px;">
        <div style="padding:40px 0">
          <img src="../assets/web/wordVip1.png" alt="" style="width:100%">
        </div>
        <div>
          <!-- <div style="color:#0762CB;font-size:20px;padding:10px 0 20px 0;">采购链</div> -->
           <div style="display:flex;padding:0 0 50px 0;align-items:center">
            <div class="left" style="width:65%;">
            <div style="font-size:26px;color:#0762CB;">会员尊享特权</div>
            <div style="padding:15px 0;">
            <img src="../assets/web/wordVip2.png" alt="" style="width:58%;height:100px">
            </div>
            
            </div>
            <div class="right" style="width:35%;">
              <img src="../assets/web/picVip1.png" alt="" style="width:92%;margin-left:8%">
            </div>
           </div>
        </div>
        <div>
          <!-- <div style="color:#0762CB;font-size:20px;padding:10px 0 20px 0;">采购链</div> -->
           <div style="display:flex;padding:0 0 10px 0;align-items:center">
            <div class="left" style="width:65%;">
            <div style="font-size:26px;color:#0762CB;">会员积分超值用途</div>
            <div style="padding:15px 0;">
            <img src="../assets/web/wordVip3.png" alt="" style="width:58%;height:120px">
            </div>
            
            </div>
            <div class="right" style="width:35%;">
              <img src="../assets/web/picVip2.png" alt="" style="width:92%;margin-left:8%">
            </div>
           </div>
        </div>
        </div>
        
        
        </div>
        <!-- 慢病中心-->
        <div v-if="this.itemIndexVip == 2" style="display:flex;justify-content:center;align-items:center;flex-direction:column">
          <div style="width:1000px;">
           
           <div style="color:#0762CB;font-size:26px;padding:40px 0 20px 0;">关 爱 慢 病 患 者，博 爱 医 药 在 行 动</div>
           <div >
           <img src="../assets/web/mbword1.png" alt="" style="width:100%;height:190px;padding:30px 0;">
           </div>
           <div style="display:flex;justify-content:space-between;align-items:center;">
            <img src="../assets/web/mbPic1.png" alt="" style="width:320px;height:210px">
            <img src="../assets/web/mbPic2.png" alt="" style="width:320px;height:210px">
            <img src="../assets/web/mbPic3.png" alt="" style="width:320px;height:210px">
          </div>
    

</div>
        </div>
        <!-- 老中医咨询 -->
        <div v-if="this.itemIndexVip == 3" style="display:flex;justify-content:center;align-items:center;flex-direction:column">
         
          <div style="width:1000px;">
           
           <div style="color:#0762CB;font-size:26px;padding:40px 0 20px 0;">博 爱 医 药 - 中 科 中 医 院</div>
           <div >
           <img src="../assets/web/zyword1.png" alt="" style="width:100%;height:190px;padding:30px 0;">
           </div>
           <div style="display:flex;justify-content:space-between;align-items:center;">
            <img src="../assets/web/zyPic1.png" alt="" style="width:320px;height:210px">
            <img src="../assets/web/zyPic2.png" alt="" style="width:320px;height:210px">
            <img src="../assets/web/zyPic3.png" alt="" style="width:320px;height:210px">
          </div>
          <div>
          <!-- <div style="color:#0762CB;font-size:20px;padding:10px 0 20px 0;">采购链</div> -->
           <div style="display:flex;padding:60px 0 10px 0;align-items:center">
            <div class="left" style="width:65%;display:flex;flex-direction:column;justify-content:space-between;height:200px">
            <div style="font-size:26px;color:#0762CB;">服 务 项 目</div>
            <div style="padding:15px 0;">
            <img src="../assets/web/zyword2.png" alt="" style="width:58%;height:100px">
            </div>
            
            </div>
            <div class="right" style="width:35%;">
              <img src="../assets/web/zyPic4.png" alt="" style="width:84%;margin-left:16%">
            </div>
           </div>
        </div>

</div>

      
        </div>
       
    
        <!-- footer -->
      <div style="width:100%;height:240px;background:#2C445C;margin-top:70px">
       <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center;border-bottom:1px solid #96A2AE ">
        <div style="display:flex;width:500px;height:90px;justify-content:space-between;align-items:center;color:#96A2AE">
        <div>首页</div>
        <div>关于我们</div>
        <div>会员服务</div>
        <div>博爱大事记</div>
        <div>供应商平台</div>
        <div>人才招聘</div>
       </div>
       <div style="width:50px;height:23px;background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;">
        <img src="../assets/web/chaxun.png" alt="" style="width:11px;height:10px;">
        <div style="font-size:12px">搜索</div>
       </div>
       </div>
       <!-- 联系电话  地址  工作时间 -->
       <div>
        <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center; ">
        <div style="width:500px;height:90px;color:#96A2AE;margin-top:30px">
          <div>联系电话：0417-5675777    <span style="margin-left:60px">备案号：辽ICP备19020664号-1</span></div>
        <div style="margin-top:10px">地址：中国·辽宁·大石桥博爱大厦总部</div>
        <div style="margin-top:10px">工作时间：周一至周六08:00-17:00</div>
      
       </div>
       <div style="background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;margin-top:40px">
        <img src="../assets/web/erweima.png" alt="" style="width:80px;height:80px;">
        
       </div>
       </div>
       </div>
      </div>
    </div>
    <div v-if="this.itemIndex == 4">
      
        <div class="top-rowBanner544444"></div>
            <!-- 关于我们导航栏 公司概况 品质服务 企业荣誉 企业文化 -->
            <div class="top-rowBanner2222" style="display:flex;justify-content:center;align-items:center">
         <div style="width:1000px;height:80px;display:flex;align-items:center;justify-content:space-between">
          <div class="left" style="display:flex">
          <div style="width:78px;height:90px;background:#0762CB;margin-top:-9px;display:flex;align-items:center;justify-content:space-around;flex-direction:column">
            <div></div>
            <img src="../assets/web/c.png" alt="" style="width:54px;height:18px">
            <div style="color:#6A93D9;font-size:15px;font-weight:600">博爱大事记</div>
            <div></div>
          </div>
          <div style="display:flex;margin-top:30px;justify-content:center;margin-left:30px">
            <img src="../assets/web/sjx.png" alt="" style="width:8px;height:16px;margin-top:2px"> 
         
           <div v-if="this.itemIndexba == 1"  style="margin-left:7px">博爱大事记  </div>
           <!-- <div v-if="this.itemIndexVip == 2"  style="margin-left:7px">慢病中心  </div>
           <div v-if="this.itemIndexVip == 3"  style="margin-left:7px">老中医咨询</div> -->
           <!-- <div v-if="this.itemIndexVip == 4"  style="margin-left:7px">企业文化</div> -->
          </div>

          </div>
          <div class="right" style="display:flex;width:400px;justify-content:space-between">
            <div></div>
            <div></div>
            <div></div>
            <div :class="{ activeAbout:  itemIndexba == 1  }"  @click="btnClickba(1)">博爱大事记 </div>
            <!-- <div :class="{ activeAbout:  itemIndexVip == 2  }"  @click="btnClickVip(2)">慢病中心  </div>
            <div  :class="{ activeAbout: itemIndexVip == 3 }"   @click="btnClickVip(3)">老中医咨询</div> -->
            <!-- <div  :class="{ activeAbout: itemIndexVip == 4 }"   @click="btnClickVip(4)">企业文化</div> -->
          </div>
         </div>
        </div>
        <div v-if="itemIndexba==1" style="display:flex;justify-content:center;align-items:center">
           <div style="width:1000px;padding:35px 0;display:flex;justify-content:center;flex-direction:column;"> 
           <div style="width:100%;padding:40px 0;border-bottom:2px dotted grey;display:flex;justify-content:space-between">
            <div style="color:#0762CB;font-size:26px">2001年</div>
            <div style="color:#000;font-size:19px"   >博爱大药房成立第一家门店</div>
           </div>
           <div style="width:100%;padding:40px 0;border-bottom:2px dotted grey;display:flex;justify-content:space-between">
            <div style="color:#0762CB;font-size:26px">2002年-2015年</div>
            <div style="color:#000;font-size:19px"   >陆续开展连锁加盟200余家门店</div>
           </div>
           <div style="width:100%;padding:40px 0;border-bottom:2px dotted grey;display:flex;justify-content:space-between">
            <div style="color:#0762CB;font-size:26px">2015年</div>
            <div style="color:#000;font-size:19px"   >与第三方平台接洽开展医药电商业务</div>
           </div>
           <div style="width:100%;padding:40px 0;border-bottom:2px dotted grey;display:flex;justify-content:space-between">
            <div style="color:#0762CB;font-size:26px">2019年</div>
            <div style="color:#000;font-size:19px"   >开发自主平台电商新零售业务</div>
           </div>
           <div style="width:100%;padding:40px 0;border-bottom:2px dotted grey;display:flex;justify-content:space-between">
            <div style="color:#0762CB;font-size:26px">2020年</div>
            <div style="color:#000;font-size:19px"   >建设开发中国医药电商小镇</div>
           </div>
           <div style="width:100%;padding:40px 0  20px 0;display:flex;justify-content:space-between">
            <div style="color:#0762CB;font-size:21px;font-weight:600">联系我们</div>
            <div style="color:#000;"   >
              <div style="font-size:22px">营口博爱医药连锁有限公司</div>
            <br/>
            <div style="margin-top:16px">联系电话：0417-5675777</div>
            
            <div  style="margin-top:8px">地址：中国·辽宁·大石桥博爱大厦总部</div>
            
            <div  style="margin-top:8px">工作时间：周一至周六08:00-17:00</div>
            </div>
            <div style="color:#000;font-size:19px;display:flex"   >
            
            <div>
              <img src="../assets/web/baewm.png" alt="" style="width:100px;height:100px">
              <div>博爱医药馆</div>
            </div>
            <div>
              <img src="../assets/web/gzh.png" alt="" style="width:100px;height:100px">
              <div>博爱公众号</div>
            </div>
            </div>
           </div>
        

           </div>
        </div>
        <!-- footer -->
      <div style="width:100%;height:240px;background:#2C445C;margin-top:20px">
       <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center;border-bottom:1px solid #96A2AE ">
        <div style="display:flex;width:500px;height:90px;justify-content:space-between;align-items:center;color:#96A2AE">
        <div>首页</div>
        <div>关于我们</div>
        <div>会员服务</div>
        <div>博爱大事记</div>
        <div>供应商平台</div>
        <div>人才招聘</div>
       </div>
       <div style="width:50px;height:23px;background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;">
        <img src="../assets/web/chaxun.png" alt="" style="width:11px;height:10px;">
        <div style="font-size:12px">搜索</div>
       </div>
       </div>
       <!-- 联系电话  地址  工作时间 -->
       <div>
        <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center; ">
        <div style="width:500px;height:90px;color:#96A2AE;margin-top:30px">
          <div>联系电话：0417-5675777    <span style="margin-left:60px">备案号：辽ICP备19020664号-1</span></div>
        <div style="margin-top:10px">地址：中国·辽宁·大石桥博爱大厦总部</div>
        <div style="margin-top:10px">工作时间：周一至周六08:00-17:00</div>
      
       </div>
       <div style="background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;margin-top:40px">
        <img src="../assets/web/erweima.png" alt="" style="width:80px;height:80px;">
        
       </div>
       </div>
       </div>
      </div>
    </div>
    <div v-if="this.itemIndex == 5">
        <!-- banner -->
        <div class="top-rowBanner5555"></div>
            <!-- 关于我们导航栏 公司概况 品质服务 企业荣誉 企业文化 -->
            <div class="top-rowBanner2222" style="display:flex;justify-content:center;align-items:center">
         <div style="width:1000px;height:80px;display:flex;align-items:center;justify-content:space-between">
          <div class="left" style="display:flex">
          <div style="width:78px;height:90px;background:#0762CB;margin-top:-9px;display:flex;align-items:center;justify-content:space-around;flex-direction:column">
            <div></div>
            <img src="../assets/web/d.png" alt="" style="width:54px;height:18px">
            <div style="color:#6A93D9;font-size:15px;font-weight:600">供应商平台</div>
            <div></div>
          </div>
          <div style="display:flex;margin-top:30px;justify-content:center;margin-left:30px">
            <img src="../assets/web/sjx.png" alt="" style="width:8px;height:16px;margin-top:2px"> 
           
           <div v-if="this.itemIndexSup == 1"  style="margin-left:7px">送货须知  </div>
           <div v-if="this.itemIndexSup == 2"  style="margin-left:7px">投资联盟</div>
           <div v-if="this.itemIndexSup == 3"  style="margin-left:7px">联系我们</div>
           <!-- <div v-if="this.itemIndexVip == 4"  style="margin-left:7px">企业文化</div> -->
          </div>

          </div>
          <div class="right" style="display:flex;width:400px;justify-content:space-between">
            <div></div>
            <div :class="{ activeAbout:  itemIndexSup == 1  }"  @click="btnClickSup(1)">送货须知  </div>
            <div :class="{ activeAbout:  itemIndexSup == 2  }"  @click="btnClickSup(2)">投资加盟 </div>
            <div  :class="{ activeAbout: itemIndexSup == 3 }"   @click="btnClickSup(3)">联系我们</div>
            <!-- <div  :class="{ activeAbout: itemIndexVip == 4 }"   @click="btnClickVip(4)">企业文化</div> -->
          </div>
         </div>
        </div>
         <!-- 送货须知 -->
        <div v-if="this.itemIndexSup == 1" style="display:flex;justify-content:center;align-items:center;flex-direction:column">
         
        <div style="width:1000px;">
          <div style="color:#0762CB;font-size:22px;padding:40px 0 20px 0;">博 爱 医 药 供 应 商 送 货 须 知</div>
        <img src="../assets/web/supword1.png" alt="" style="width:100%">
        </div>
        
        
        </div>
        <!-- 投资加盟-->
        <div v-if="this.itemIndexSup== 2" style="display:flex;justify-content:center;align-items:center;flex-direction:column">
          <div style="width:1000px;">
          <div style="color:#0762CB;font-size:22px;padding:40px 0 20px 0;">投 资 加 盟</div>
        <img src="../assets/web/tzjm.png" alt="" style="width:100%">
        </div>
        </div>
        <!-- 联系我们 -->
        <div v-if="this.itemIndexSup == 3" style="display:flex;justify-content:center;align-items:center;flex-direction:column">
         
          <div style="width:1000px;">
           
            <div style="width:100%;padding:40px 0  20px 0;display:flex;justify-content:space-between">
            <div style="color:#0762CB;font-size:21px;font-weight:600">联系我们</div>
            <div style="color:#000;"   >
              <div style="font-size:22px">营口博爱医药连锁有限公司</div>
            <br/>
            <div style="margin-top:16px">联系电话：0417-5675777</div>
            
            <div  style="margin-top:8px">地址：中国·辽宁·大石桥博爱大厦总部</div>
            
            <div  style="margin-top:8px">工作时间：周一至周六08:00-17:00</div>
            </div>
            <div style="color:#000;font-size:19px;display:flex"   >
            
            <div>
              <img src="../assets/web/baewm.png" alt="" style="width:100px;height:100px">
              <div>博爱医药馆</div>
            </div>
            <div>
              <img src="../assets/web/gzh.png" alt="" style="width:100px;height:100px">
              <div>博爱公众号</div>
            </div>
            </div>
           </div>
           <div>
            <img src="../assets/web/map.png" alt="" style="width:100%;margin-top:30px">
           </div>

</div>

      
        </div>
       
    
        <!-- footer -->
      <div style="width:100%;height:240px;background:#2C445C;margin-top:50px">
       <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center;border-bottom:1px solid #96A2AE ">
        <div style="display:flex;width:500px;height:90px;justify-content:space-between;align-items:center;color:#96A2AE">
        <div>首页</div>
        <div>关于我们</div>
        <div>会员服务</div>
        <div>博爱大事记</div>
        <div>供应商平台</div>
        <div>人才招聘</div>
       </div>
       <div style="width:50px;height:23px;background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;">
        <img src="../assets/web/chaxun.png" alt="" style="width:11px;height:10px;">
        <div style="font-size:12px">搜索</div>
       </div>
       </div>
       <!-- 联系电话  地址  工作时间 -->
       <div>
        <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center; ">
        <div style="width:500px;height:90px;color:#96A2AE;margin-top:30px">
          <div>联系电话：0417-5675777    <span style="margin-left:60px">备案号：辽ICP备19020664号-1</span></div>
        <div style="margin-top:10px">地址：中国·辽宁·大石桥博爱大厦总部</div>
        <div style="margin-top:10px">工作时间：周一至周六08:00-17:00</div>
      
       </div>
       <div style="background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;margin-top:40px">
        <img src="../assets/web/erweima.png" alt="" style="width:80px;height:80px;">
        
       </div>
       </div>
       </div>
      </div>
    </div>
    <div v-if="this.itemIndex == 6">
        <!-- banner -->
        <div class="top-rowBanner6666"></div>
            <!-- 关于我们导航栏 公司概况 品质服务 企业荣誉 企业文化 -->
            <div class="top-rowBanner2222" style="display:flex;justify-content:center;align-items:center">
         <div style="width:1000px;height:80px;display:flex;align-items:center;justify-content:space-between">
          <div class="left" style="display:flex">
          <div style="width:78px;height:90px;background:#0762CB;margin-top:-9px;display:flex;align-items:center;justify-content:space-around;flex-direction:column">
            <div></div>
            <img src="../assets/web/e.png" alt="" style="width:54px;height:18px">
            <div style="color:#6A93D9;font-size:16px;font-weight:600">人才招聘</div>
            <div></div>
          </div>
          <div style="display:flex;margin-top:30px;justify-content:center;margin-left:30px">
            <img src="../assets/web/sjx.png" alt="" style="width:8px;height:16px;margin-top:2px"> 
           
           <div v-if="this.itemIndexzp == 1"  style="margin-left:7px">社会招聘 </div>
           <div v-if="this.itemIndexzp == 2"  style="margin-left:7px">员工风采  </div>
           <!-- <div v-if="this.itemIndexVip == 3"  style="margin-left:7px">老中医咨询</div> -->
           <!-- <div v-if="this.itemIndexVip == 4"  style="margin-left:7px">企业文化</div> -->
          </div>

          </div>
          <div class="right" style="display:flex;width:400px;justify-content:space-between">
            <div></div>
            <div></div>
            <div :class="{ activeAbout:  itemIndexzp == 1  }"  @click="btnClickzp(1)">社会招聘  </div>
            <div :class="{ activeAbout:  itemIndexzp == 2  }"  @click="btnClickzp(2)">员工风采  </div>
            <!-- <div  :class="{ activeAbout: itemIndexVip == 3 }"   @click="btnClickVip(3)">老中医咨询</div> -->
            <!-- <div  :class="{ activeAbout: itemIndexVip == 4 }"   @click="btnClickVip(4)">企业文化</div> -->
          </div>
         </div>
        </div>
         <!-- 社会招聘 -->
        <div v-if="this.itemIndexzp == 1" style="display:flex;justify-content:center;align-items:center;flex-direction:column">
         
        <div style="width:1000px;background">
          <div style="color:#000;font-size:26px;padding:40px 0 20px 0;">基础信息
          </div>
          <el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" :inline="true" style="display:flex;justify-content:space-between">
  <el-form-item label="姓名" required=“true”>
    <el-input v-model="formLabelAlign.name" style="width:300px" placeholder="请输入姓名"></el-input>
  </el-form-item>
  <el-form-item label="身份证号" required=“true”>
    <el-input v-model="formLabelAlign.region" style="width:300px" placeholder="请输入身份证号"></el-input>
  </el-form-item>
  <el-form-item label="手机号" required=“true”>
    <el-input v-model="formLabelAlign.type" style="width:300px" placeholder="请输入手机号" ></el-input>
  </el-form-item>
  
</el-form>
<el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" :inline="true" style="display:flex;justify-content:space-between;margin-top:30px">
  <el-form-item label="性别" required=“true”>
    <el-input v-model="formLabelAlign.name" style="width:300px" placeholder="请输入性别"></el-input>
  </el-form-item>
  <el-form-item label="户口类型" required=“true”>
    <el-input v-model="formLabelAlign.region" style="width:300px" placeholder="请输入户口类型"></el-input>
  </el-form-item>
  <el-form-item label="居住地址" required=“true”>
    <el-input v-model="formLabelAlign.type" style="width:300px" placeholder="请输入居住地址"></el-input>
  </el-form-item>
  
</el-form>
<el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" :inline="true" style="display:flex;justify-content:space-between;margin-top:30px">
  <el-form-item label="最高学历" required=“true”>
    <el-input v-model="formLabelAlign.name" style="width:300px" placeholder="请输入最高学历"></el-input>
  </el-form-item>
  <el-form-item label="毕业院校" required=“true”>
    <el-input v-model="formLabelAlign.region" style="width:300px" placeholder="请输入院校"></el-input>
  </el-form-item>
  <el-form-item label="所学专业" required=“true”>
    <el-input v-model="formLabelAlign.type" style="width:300px" placeholder="请输入所学专业"></el-input>
  </el-form-item>
  
</el-form>
<div style="color:#000;font-size:26px;padding:40px 0 20px 0;">求职状态</div>
<el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" :inline="true" style="display:flex;justify-content:space-between;margin-top:30px">
  <el-form-item label="应聘职位" required=“true”>
    <el-input v-model="formLabelAlign.name" style="width:300px;" placeholder="请输入应聘职位" ></el-input>
  </el-form-item>
  <el-form-item label="期望薪资" required=“true”>
    <el-input v-model="formLabelAlign.region" style="width:300px" placeholder="请输入期望薪资"></el-input>
  </el-form-item>
  <el-form-item label="期望地点" required=“true”>
    <el-input v-model="formLabelAlign.type" style="width:300px" placeholder="请输入期望地点"></el-input>
  </el-form-item>
  
</el-form>
<div style="color:#000;font-size:26px;padding:40px 0 20px 0;">工作经历</div>
<el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" :inline="true" style="display:flex;justify-content:space-between;margin-top:30px">
  <el-form-item label="公司名称"  required=“true”>
    <el-input v-model="formLabelAlign.name" style="width:300px;" placeholder="请输入公司名称" ></el-input>
  </el-form-item>
  <el-form-item label="职位名称" required=“true”>
    <el-input v-model="formLabelAlign.region" style="width:300px" placeholder="请输入职位名称"></el-input>
  </el-form-item>
  <el-form-item label="在职时间" required=“true”>
    <el-input v-model="formLabelAlign.type" style="width:300px" placeholder="请输入在职时间"></el-input>
  </el-form-item>
  
</el-form>
<el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" :inline="true" style="display:flex;justify-content:space-between;margin-top:30px">
  <el-form-item label="工作职责" required=“true” >
  <textarea name="1" id="" cols="30" rows="10" style="width:1000px;height:100px" placeholder="请输入工作职责"></textarea>
  </el-form-item>
  
  
</el-form>
<div style="color:#000;font-size:26px;padding:40px 0 20px 0;">项目经历</div>
<el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" :inline="true" style="display:flex;justify-content:space-between;margin-top:30px">
  <el-form-item label="项目名称" required=“true” >
    <el-input v-model="formLabelAlign.name" style="width:300px;" placeholder="请输入项目名称" ></el-input>
  </el-form-item>
  <el-form-item label="担任角色" required=“true”>
    <el-input v-model="formLabelAlign.region" style="width:300px" placeholder="请输入担任角色"></el-input>
  </el-form-item>
  <el-form-item label="项目周期" required=“true”>
    <el-input v-model="formLabelAlign.type" style="width:300px" placeholder="请输入项目周期"></el-input>
  </el-form-item>
  
</el-form>
<el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" :inline="true" style="display:flex;justify-content:space-between;margin-top:30px">
  <el-form-item label="项目描述" required=“true”>
  <textarea name="1" id="" cols="30" rows="10" style="width:1000px;height:100px" placeholder="请输入项目描述"></textarea>
  </el-form-item>
  
  
</el-form>
<div style="color:#000;font-size:26px;padding:40px 0 20px 0;">自我评价</div>
<el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" :inline="true" style="display:flex;justify-content:space-between;margin-top:30px">
  <el-form-item label="评价内容" required=“true”>
  <textarea name="1" id="" cols="30" rows="10" style="width:1000px;height:100px" placeholder="请输入自我评价"></textarea>
  </el-form-item>
  
  
</el-form>
        </div>
        
        
        </div>
        <!-- 员工风采-->
        <div v-if="this.itemIndexzp == 2" style="display:flex;justify-content:center;align-items:center;flex-direction:column">
          <div style="width:1000px;">
           
           <div style="color:#0762CB;font-size:26px;padding:40px 0 20px 0;">员 工 风 采</div>
          
           <div style="display:flex;justify-content:space-between;align-items:center;">
            <img src="../assets/web/p1.png" alt="" style="width:320px;height:210px">
            <img src="../assets/web/p2.png" alt="" style="width:320px;height:210px">
            <img src="../assets/web/p3.png" alt="" style="width:320px;height:210px">
          </div>
          <div style="padding:10px 0;display:flex">
            <div><img src="../assets/web/p4.png" alt="" style="width:660px;height:360px"></div>
            <div style="margin-left:20px">
              <img src="../assets/web/p5.png" alt="" style="width:320px;height:175px;">
              <img src="../assets/web/p6.png" alt=""  style="width:320px;height:175px;margin-top:6px">
            </div>
          </div>
          <div style="display:flex;justify-content:space-between;align-items:center;">
            <img src="../assets/web/p7.png" alt="" style="width:320px;height:210px">
            <img src="../assets/web/p8.png" alt="" style="width:320px;height:210px">
            <img src="../assets/web/p9.png" alt="" style="width:320px;height:210px">
          </div>
    

</div>
        </div>
       
     
       
    
        <!-- footer -->
      <div style="width:100%;height:240px;background:#2C445C;margin-top:70px">
       <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center;border-bottom:1px solid #96A2AE ">
        <div style="display:flex;width:500px;height:90px;justify-content:space-between;align-items:center;color:#96A2AE">
        <div>首页</div>
        <div>关于我们</div>
        <div>会员服务</div>
        <div>博爱大事记</div>
        <div>供应商平台</div>
        <div>人才招聘</div>
       </div>
       <div style="width:50px;height:23px;background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;">
        <img src="../assets/web/chaxun.png" alt="" style="width:11px;height:10px;">
        <div style="font-size:12px">搜索</div>
       </div>
       </div>
       <!-- 联系电话  地址  工作时间 -->
       <div>
        <div style="display:flex;width:83.6%;margin-left:8.5%;justify-content:space-between;align-items:center; ">
        <div style="width:500px;height:90px;color:#96A2AE;margin-top:30px">
          <div>联系电话：0417-5675777    <span style="margin-left:60px">备案号：辽ICP备19020664号-1</span></div>
        <div style="margin-top:10px">地址：中国·辽宁·大石桥博爱大厦总部</div>
        <div style="margin-top:10px">工作时间：周一至周六08:00-17:00</div>
      
       </div>
       <div style="background:#6C7C8D;color:#96A2AE;display:flex;justify-content:space-around;align-items:center;margin-top:40px">
        <img src="../assets/web/erweima.png" alt="" style="width:80px;height:80px;">
        
       </div>
       </div>
       </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemIndex: 1,
      itemIndexAbout:1,
      itemIndexVip:1,
      itemIndexba:1,
      itemIndexSup:1,
      itemIndexzp:1,
      labelPosition: 'top',
        formLabelAlign: {
          name: '',
          region: '',
          type: ''
        }
    };
  },

  methods: {
    btnClick(index) {
      this.itemIndex = index;
    },
    btnClickVip(index) {
      this.itemIndexVip = index;
    },
    btnClickba(index) {
      this.itemIndexba = index;
    },
    btnClickAbout(index) {
      this.itemIndexAbout = index;
    },
    btnClickSup(index) {
      this.itemIndexSup = index;
    },
    btnClickzp(index) {
      this.itemIndexzp= index;
    },
  },
};
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
}
.nav {
  height: 80px;
  background-color: rgb(255, 255, 255);
  color: black;
  display: flex;
  justify-content: space-between;
}
.banner {
  height: 80px;
  background-color: rgb(245, 228, 228);
  color: black;
  display: flex;
  justify-content: space-between;
}
.center {
  width:1100px;
  list-style: none;
  /* margin: 0 auto; */
  /* background-color: pink; */
}
li {
  color: rgb(24, 5, 5);
  float: left;
}
a:hover {
  /* background-color: violet; */
  color: #0762cb;
  text-decoration: underline;
}
a {
  /* 
        一定注意要把a转成行内块元素，
        如果转的是块元素那么短竖线会掉下去，
        因为块级元素是独占一行的
    */
  display: inline-block;
  padding: 0 30px;
  line-height: 80px;
  text-decoration: none;
  color: black;
}

.top-rowBanner {
  background: #f8f8f8;
  padding: 3em 0 4em 0;
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/indexBanner.png") no-repeat 0px 0px;
  background-size: 100% 100%;
  height: 540px;
}
.top-rowBanner666 {
  background: #f8f8f8;
  /* padding: 3em 0 4em 0; */
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/cjx.png") no-repeat 0px 0px;
  background-size: 100% 100%;
  height:260px;
}
.top-rowBanner6666 {
  background: #f8f8f8;
  /* padding: 3em 0 4em 0; */
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/join.jpg") no-repeat 0px 0px;
  background-size: 100% 100%;
  height: 540px;
}
.top-rowBanner1 {
  background: #f8f8f8;
  padding: 3em 0 4em 0;
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/size.png") no-repeat 0px 0px;
  background-size: 100% 100%;
  height: 540px;
  margin-top:35px;
}
.top-rowBanner2 {
  background: #f8f8f8;
  padding: 3em 0 4em 0;
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/indexAbout.png") no-repeat 0px 0px;
  background-size: 100% 100%;
  height: 540px;
  /* margin-top:35px; */
}
.top-rowBannerAbout {
  background: #f8f8f8;
  padding: 3em 0 4em 0;
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/1.png") no-repeat 0px 0px;
  background-size: 100% 100%;
  height:100px;
  /* margin-top:35px; */
}
.top-rowBanner2222 {
  background: #f8f8f8;
  /* padding: 3em 0 4em 0; */
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: white no-repeat 0px 0px;
  background-size: 100% 100%;
  height:80px;
  border-bottom:3px dotted grey;

  /* margin-top:35px; */
}
.top-rowBanner3{
  background: #f8f8f8;
  padding: 3em 0 4em 0;
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/map.png") no-repeat 0px 0px;
  background-size: 100% 100%;
  height:220px;
  /* margin-top:35px; */
}
.top-rowBanner5{
  background: #f8f8f8;
  padding: 3em 0 4em 0;
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/bannerVip.png") no-repeat 0px 0px;
  background-size: 100% 100%;
  height:540px;
  /* margin-top:35px; */
}
.top-rowBanner5555{
  background: #f8f8f8;
  padding: 3em 0 4em 0;
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/supbanner.png") no-repeat 0px 0px;
  background-size: 100% 100%;
  height:540px;
  /* margin-top:35px; */
}
.top-rowBanner6{
  background: #f8f8f8;
  padding: 3em 0 4em 0;
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/join.jpg") no-repeat 0px 0px;
  background-size: 100% 100%;
  height:540px;
  /* margin-top:35px; */
}
.top-rowBanner544444{
  background: #f8f8f8;
  padding: 3em 0 4em 0;
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/badsj.png") no-repeat 0px 0px;
  background-size: 100% 100%;
  height:540px;
  /* margin-top:35px; */
}
.togethter{
  background: #f8f8f8;
  padding: 1em 0 1em 0;
  /* border-top:1px solid #f1efef; */
  /* border-bottom:1px solid #f1efef; */
  background: url("../assets/web/toge.png") no-repeat 0px 0px;
  background-size: 100% 100%;
  height:540px;
  /* margin-top:35px; */
}
.active {
  color: #0762cb;
  text-decoration: underline;
}
.activeAbout {
  color: #0762cb;
  text-decoration: underline;
}
</style>